import { Row, Col, Affix } from 'antd';
import navGray from './assets/img/navGray.png';
import beneficio1 from './assets/img/beneficio1.png';
import beneficio2 from './assets/img/beneficio2.png';
import beneficio3 from './assets/img/beneficio3.png';
import world from './assets/img/world.png';
import arrow from './assets/img/arrow.png';
import React from 'react';
import './App.css';

function App() {
    return (
        <div>
			<Affix offsetTop={0}>
				<Row className='Contenedor-Navbar'>
					<Col span={16}>
						<img className='Imagen-Navbar' src={navGray}/>
					</Col>
					<Col span={8} style={{display:'flex', justifyContent:'end'}}>
						<div className='Contenedor-Menu'>
							X
						</div>
					</Col>
				</Row>
			</Affix>

			<Row className='Contenedor-Fila-Principal'>
				<Col xs={24} sm={24} md={24} lg={10}>

					<div className='Contenedor-Texto-Bienvenida'>

						<h2 className='Texto-Titulo-Bienvenida'>¡Bienvenido!</h2>
						<p className='Texto-Subtitulo-Bienvenida'>Es un hecho establecido desde hace mucho tiempo que un lector se distraerá con el contenido legible de una página cuando mire su diseño.</p>
						<Row>
							<Col xs={0} sm={0} md={0} lg={24} style={{width:'100%'}}>
								<div>


									<div className='Boton-Audio'>
										<img src={world} style={{width:'auto',height:'3vh'}}/>
										<div className='Texto-Subtitulo-Bienvenida Texto-Audio'>Ir a GROW</div>
										<img src={arrow} className='Flecha-Audio' style={{width:'auto',height:'2vh'}}/>
									</div>
								
								
								</div>

							</Col>
						</Row>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={14}>
					<div className='Contenedor-Video'>
						<iframe width="100%" height="100%" src="https://www.youtube.com/embed/2EY_3yHRhBw" title="YouTube video player" style={{objectFit:'cover'}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
					</div>
				</Col>
				<Col sm={24} md={24} lg={0} style={{width:'100%'}}>
					<div>
						<div>
							<div className='Boton-Audio-Inferior'>
								<img src={world} style={{width:'auto',height:'3vh'}}/>
								<div className='Texto-Subtitulo-Bienvenida Texto-Audio'>Ir a GROW</div>
								<img src={arrow} className='Flecha-Audio-Inferior'/>
							</div>
						</div>
					</div>
				</Col>
			</Row>




			<Row className='Contenedor-Fila'>
				<div style={{display:'flex', justifyContent:'center', width:'100%'}}>
					<h2 className='SubTitulo-Tarjeta'>Beneficios</h2>
				</div>	
				<Row>
					<Col sm={24} md={24} lg={8}>


						<Row className='Contenedor-Tarjeta Contenedor-Beneficios-Uno'>
							<Col md={12} lg={24} className='Contenedor-Texto-Tarjeta'>
								<h3>Beneficios</h3>
								<p>es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.</p>
							</Col>
							<Col md={12} lg={24} className='Contenedor-Imagen'>
								<img className='Imagen-Tarjeta' src={beneficio1}/>
							</Col>
						</Row>
					</Col>
					<Col sm={24} md={24} lg={8}>


						<Row className='Contenedor-Tarjeta Contenedor-Beneficios-Dos'>
							<Col md={12} lg={24} className='Contenedor-Texto-Tarjeta'>
								<h3>Beneficios</h3>
								<p>es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.</p>
							</Col>
							<Col md={12} lg={24} className='Contenedor-Imagen'>
								<img className='Imagen-Tarjeta' src={beneficio2}/>
							</Col>
						</Row>
					</Col>
					<Col sm={24} md={24} lg={8}>
						<Row className='Contenedor-Tarjeta Contenedor-Beneficios-Tres'>
							<Col md={12} lg={24} className='Contenedor-Texto-Tarjeta'>
								<h3>Beneficios</h3>
								<p>es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.</p>
							</Col>
							<Col md={12} lg={24}  className='Contenedor-Imagen'>
								<img className='Imagen-Tarjeta' src={beneficio3}/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Row>
			<Row className='Contenedor-Fila'>
				<div style={{display:'flex', justifyContent:'center', width:'100%'}}>
					<h2 className='SubTitulo-Tarjeta'>¿Qué encontraremos?</h2>
				</div>
				<Row>
					<Col sm={24}  md={24} lg={8}>
						<Row className='Contenedor-Tarjeta Contenedor-Encontraremos-Uno'>
							<Col md={12} lg={24} className='Contenedor-Texto-Tarjeta'>
								<h3>Beneficios</h3>
								<p>es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.</p>
							</Col>
							<Col md={12} lg={24}  className='Contenedor-Imagen'>
								<img className='Imagen-Tarjeta' src={beneficio1}/>
							</Col>
						</Row>
					</Col>
					<Col sm={24} md={24} lg={8}>
						<Row className='Contenedor-Tarjeta Contenedor-Encontraremos-Dos'>
							<Col md={12} lg={24} className='Contenedor-Texto-Tarjeta'>
								<h3>Beneficios</h3>
								<p>es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.</p>
							</Col>
							<Col md={12} lg={24}  className='Contenedor-Imagen'>
								<img className='Imagen-Tarjeta' src={beneficio2}/>
							</Col>
						</Row>
					</Col>
					<Col sm={24} md={24} lg={8}>
						<Row className='Contenedor-Tarjeta Contenedor-Encontraremos-Tres'>
							<Col md={12} lg={24} className='Contenedor-Texto-Tarjeta'>
								<h3>Beneficios</h3>
								<p>es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.</p>
							</Col>
							<Col md={12} lg={24}  className='Contenedor-Imagen'>
								<img className='Imagen-Tarjeta' src={beneficio3}/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Row>
		</div>

    );
}

export default App;
